import request from '@/service/lib/request';
// 脚本、混剪、分发

/**
 * 脚本分页查询
 * @author lb
 */
export function scriptPage(data) {
    return request({
      url: '/mashup/script_page',
      method: 'post',
      data
    });
}

/**
 * 脚本新增/修改
 * @author lb
 */
export function saveOrUpdateByScript(data) {
    return request({
      url: '/mashup/save_or_update_script',
      method: 'post',
      data
    });
}

/**
 * 镜头分页查询
 * @author lb
 */
export function shotGroupPage(data) {
    return request({
      url: '/mashup/shot_group_page',
      method: 'post',
      data
    });
}

/**
 * 镜头新增/修改
 * @author lb
 */
export function saveOrUpdateByShotGroup(data) {
    return request({
      url: '/mashup/save_or_update_shot_group',
      method: 'post',
      data
    });
}

/**
 * 素材分页查询
 * @author lb
 */
export function materialPage(data) {
  return request({
    url: '/mashup/material_page',
    method: 'post',
    data
  });
}

/**
 * 素材修改
 * @author lb
 */
export function saveOrUpdateByMaterial(data) {
  return request({
    url: '/mashup/update_material',
    method: 'post',
    data
  });
}

/**
 * 生成镜头分组
 * @author lb
 */
export function generateShotGroup(data) {
  return request({
    url: '/mashup/generate_shot_group',
    method: 'post',
    data
  });
}

/**
 * 生成台词
 * @author lb
 */
export function generateLine(data) {
  return request({
    url: '/mashup/generate_line',
    method: 'post',
    data
  });
}

/**
 * 语音合成模板配置-分页查询
 * @author lb
 */
export function audioTmplConfigPage(data) {
  return request({
    url: '/mashup/audio_tmpl_config_page',
    method: 'post',
    data
  });
}

/**
 * 语音合成模板配置-新增/修改
 * @author lb
 */
export function saveOrUpdateAudioTmplConfig(data) {
  return request({
    url: '/mashup/save_or_update_audio_tmpl_config',
    method: 'post',
    data
  });
}

/**
 * 语音合成模板配置-删除
 * @author lb
 */
export function deleteAudioTmplConfig(data) {
  return request({
    url: '/mashup/delete_audio_tmpl_config',
    method: 'post',
    data
  });
}

/**
 * 语音合成模板配置-全部
 * @author lb
 */
export function audioTmplConfigAll() {
  return request({
    url: '/mashup/audio_tmpl_config_all',
    method: 'get'
  });
}

/**
 * 批量生成Mp3
 * @author lb
 */
export function batchGenerateMp3(data) {
  return request({
    url: '/mashup/batch_generate_mp3',
    method: 'post',
    data
  });
}

/**
 * 语音合成模板配置-语音示例生成
 * @author lb
 */
export function generateAudioTmplMp3(data) {
  return request({
    url: '/mashup/generate_audio_tmpl_mp3',
    method: 'post',
    data
  });
}

/**
 * 生成混剪任务
 * @author lb
 */
export function generateMashupTask(data) {
  return request({
    url: '/mashup/generate_mashup_task',
    method: 'post',
    data
  });
}

/**
 * 混剪任务-分页查询
 * @author lb
 */
export function mashupTaskPage(data) {
  return request({
    url: '/mashup/mashup_task_page',
    method: 'post',
    data
  });
}

/**
 * 混剪任务-修改
 * @author lb
 */
export function updateMashupTask(data) {
  return request({
    url: '/mashup/update_mashup_task',
    method: 'post',
    data
  });
}

/**
 * 混剪任务-删除
 * @author lb
 */
export function deleteMashupTask(data) {
  return request({
    url: '/mashup/delete_mashup_task',
    method: 'post',
    data
  });
}

/**
 * 混剪任务-批量合成
 * @author lb
 */
export function mashupTaskCompound(data) {
  return request({
    url: '/mashup/mashup_task_compound',
    method: 'post',
    data
  });
}
