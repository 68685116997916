import request from '@/service/lib/request';
// 文件管理

/**
 * 分页
 * @author lb
 */
export function queryList(data) {
    return request({
      url: '/file_info/get_file_info_page',
      method: 'post',
      data
    });
}

/**
 * 上传
 * @author lb
 */
export function uploadObs(data) {
  return request({
    url: '/file_info/upload',
    method: 'post',
    data
  });
}

/**
 * AI生图
 * @author lb
 */
export function aiImageUpload(data) {
  return request({
    url: '/file_info/ai_image_upload',
    method: 'post',
    data
  });
}

export function download(data) {
  return request({
    url: '/file_info/download',
    method: 'post',
    responseType: 'blob',
    data
  });
}
